import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import SongRow from "./SongRow";
import { NavLink } from "react-router-dom";
function SongPage(props) {
  const [currentSong, setCurrentSong] = useState({});
  const { singer, song } = useParams();

  useEffect(() => {
    const findSong = props.allSongs.find(
      (s) =>
        s.songName.trim() == song &&
        s.singers.find((si) => si.name.trim() == singer)
    );
    if (findSong) {
      setCurrentSong(findSong);
    }
  }, [props.allSongs]);

    useEffect(() => {
      document.title = ' היכל הנגינה : '+ currentSong?.singers?.map((s) => s.name + "") + "-" +song
    }, [currentSong]);
  return (
    <div>
      <div className="Breadcrumb">
        <NavLink to={`זמרים`}> כל הזמרים </NavLink>
        {" >>"}
        {currentSong?.singers?.map((s) => {
          return (
            <NavLink to={`/זמרים/${s.name}`} key={s.id}>
              {s.name + ""}
            </NavLink>
          );
        })}
      </div>
      <h1 className="pageTitle">{song}</h1>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    allSongs: state.allSongs,
  };
};
export default connect(mapStateToProps)(SongPage);
