import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import backgroundImage  from '../img/Background.jpg'
import Parallax from 'react-rellax'
import { NavLink } from "react-router-dom";
import Search from "../Search";
function FirstScreen(props) {
const [searchType,setSearchType] =useState("זמר")
const [searchTxt,setSearchText]= useState("")
const singersTxt= props.allSingers.slice(0,5).map(s=>
        <NavLink key={s.id} to={`/זמרים/${s.name}`}> 
        {s.name}  
     </NavLink>) 
const songsTxt= props.allSongs.slice(0,5).map(s=>

        <NavLink key={s.id} to={`/שירים/${s.singers[0].name?.trim()}/${s.songName.trim()}`}> 
        
        {s.songName}  
     </NavLink>)
const playlistsTxt= props.allPlaylists.slice(0,5).map(p=>
        <NavLink key={p.playlistId} to={`/פלייליסטים/${p.playlistName?.trim()}`}> 
        {p.playlistName}  
     </NavLink>)
  return ( 
    <>
          <div className="hero" style={{ backgroundImage: `url(${backgroundImage})` }}>
  <div>
  
  <Parallax speed={5} horizontal={true}>
    
  
<div className="bigTitle">
  <div style={{color:"#051a69"}}>
  היכל הנגינה</div>
 <div style={{marginTop:0}}>
 עולם של מוזיקה חרדית</div>
  </div></Parallax>

<div>
<Parallax speed={2} horizontal={true}>
  אלפי שירים מחכים לך</Parallax>

  
<Parallax speed={0} horizontal={true}>
  מה תרצו לשמוע?</Parallax></div>
  <div className="searchBox">
  <div className="searchTabs">
    <div className={searchType=="זמר"?"searchTabsActive":"searchTabsinactive"} onClick={()=>setSearchType("זמר")}>זמר</div>
    <div className={searchType=="שיר"?"searchTabsActive":"searchTabsinactive"} onClick={()=>setSearchType("שיר")}>שיר</div>
    <div className={searchType=="פלייליסט"?"searchTabsActive":"searchTabsinactive"} onClick={()=>setSearchType("פלייליסט")}>פלייליסט</div>
  </div>
  <Search searchTxt={searchTxt} updateSearchTxt={e=>setSearchText(e)}/>
 <div className="txtUnderSearch">
 {searchType=="זמר"&&singersTxt}
      {searchType=="זמר"&&<NavLink to={`/זמרים`}> 
       כל הזמרים 
     </NavLink>}
  {searchType=="שיר"&&songsTxt}
  {searchType=="פלייליסט"&&playlistsTxt}
  {searchType=="פלייליסט"&&<NavLink to={`/פלייליסטים`}> 
       כל הזמרים 
     </NavLink>}
 </div>  </div>
</div>
<div>
</div>
    </div>
<div style={{height:"500vh"}}></div>
    </>
  );
}


const mapStateToProps = (state) => {
  return {
    allPlaylists:state.allPlaylists,
    user:state.user,
    allSongs:state.allSongs,
    allSingers:state.allSingers
  };
};


export default connect(mapStateToProps)(FirstScreen);

