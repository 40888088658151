import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { connect } from "react-redux";

import { GoogleOAuthProvider,GoogleLogin,useGoogleLogin } from '@react-oauth/google';

const BtnLogin = ({updateUser}) => {
  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      // fetching userinfo can be done on the client or the server
      const userInfo = await axios
        .get(`https://music.318.co.il/api/Users/Login/${tokenResponse.access_token}`)
        .then(res => res.data);
    updateUser(userInfo);
    },
    // flow: 'implicit', // implicit is the default
    });
  return (
    <div>
      <button onClick={() => googleLogin()} className="loginButton">

התחברות
      </button>
    </div>
  );
};



function Login(props) {  
    
  useEffect(() => {
    let userToken = localStorage.getItem('userToken');
if(userToken){
  checkUserToken(userToken)
}
  }, []);
const [userImg,setUserImg] = useState(undefined)
  useEffect(() => {
 if(props.user?.picture){
  setUserImg(props.user.picture)
 }
  }, [props.user]);

  const checkUserToken=async userToken=>{
    const response = await axios.get(`https://music.318.co.il/api/Users/GetUserByUserToken/${userToken}`);
if(response.data?.token){
props.updateUser(response.data)

  }
  else{
    localStorage.removeItem("")
  }}
  const handleLogin = async (user) => {
   
localStorage.setItem("userToken",user?.token)
props.updateUser(user)
}
    
const logout=()=>{
  props.updateUser(undefined)
  localStorage.removeItem("userToken")
}
  return (
    <div className="login">
              <GoogleOAuthProvider clientId="705172714103-a6d05b0jm768f406ak983b1mf2gc5q4l.apps.googleusercontent.com">
{props.user?.picture?<div>
  <div className="row">
  <div>
  <div>שלום
    {" "}
    <u>{props.user.name}</u> </div>
  <div style={{cursor:"pointer"}} onClick={()=>logout()}>התנתק</div></div>
  </div>
</div>:
<BtnLogin updateUser={user=>handleLogin(user)}/>}
</GoogleOAuthProvider>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
   updateUser: (user) => {
      dispatch({
        type: "UPDATE_USER",
        payload: user,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
