import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import MainSite from "./MainSite";
import SingerPage from "./SingerPage";
import SongPage from "./SongPage";
import Login from "./Login";
import { NavLink } from "react-router-dom";
import backgroundImage from "./img/Background.jpg"
import Playlist from "./Playlist";
import AllplaylistScreen from "./screens/AllplaylistScreen";
import PlaylistScreen from "./screens/PlaylistScreen";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import FirstScreen from "./screens/FirstScreen";
import Navigation from "./screens/Navigation";
function App(props) {
  const getAllSongs = async () => {
    try {
      const response = await fetch("https://music.318.co.il/api/songs");

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      let result = await response.json();
      // let currentIndex = result.length,
      //   randomIndex;

      // // While there remain elements to shuffle.
      // while (currentIndex !== 0) {
      //   // Pick a remaining element.
      //   randomIndex = Math.floor(Math.random() * currentIndex);
      //   currentIndex--;

      //   // And swap it with the current element.
      //   [result[currentIndex], result[randomIndex]] = [
      //     result[randomIndex],
      //     result[currentIndex],
      //   ];
      // }

      props.updateSongs(result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getPlaylists = async () => {
    try {
      const response = await fetch("https://music.318.co.il/api/playlists");

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      let result = await response.json();
       props.updatePlaylists(result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getAllSongs();
    getPlaylists();

  }, []);
  useEffect(() => {
    document.title = 'היכל הנגינה - עולם המוזיקה החרדי';
  }, []);

  return (
    <Router>
      <div className="App">
        <div className="upWeb" >
        <NavLink to="/">  <div className="logo">
            <strong style={{color:"#FD6F01"}}>היכל</strong>
            <span style={{ color: "#051a69" }}>הנגינה</span>
          </div></NavLink>
      

        <Navigation/>
          <div>
          <Login/>
          </div>          
          </div>          
        <Playlist/>
        <Routes>
          <Route path="/" element={<FirstScreen />} />
          <Route path="/login" element={<div>Login</div>} />
          <Route path="זמרים" element={<MainSite />} />
          <Route path="זמרים/:page" element={<SingerPage />} />
          <Route path="שירים/:singer/:song" element={<SongPage />} />
          <Route path="פלייליסטים" element={<AllplaylistScreen />} />
          <Route path="פלייליסטים/:name" element={<PlaylistScreen />} />
        </Routes>
      </div>
    </Router>
  );
}
const mapStateToProps = (state) => {
  return {
    allSongs: state.allSongs,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateSongs: (allSongs) => {
      dispatch({
        type: "UPDATE_SONGS",
        payload: allSongs,
      });
    },
    updatePlaylists: (playlists) => {
      dispatch({
        type: "UPDATE_ALL_PLAYLISTS",
        payload: playlists,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
