import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
function AddSong(props) {
  const [songName, setSongName] = useState("");
  const [admin, setAdmin] = useState("TST");
  const [youtubeId, setYoutubeId] = useState("");
  const [youtubeURL, setYoutubeURL] = useState("");
  const [singers, setSingers] = useState("");
  const [moreSingers, setMoreSingers] = useState("");
  const [volumeList, setVolumeList] = useState([]);
  const [kcmList, setKcmList] = useState([]);
  const [volumeSelected, setVolumeSelected] = useState(0);
  useEffect(() => {
    if (kcmList.length > 0) {
      const kcm = kcmList[0];
      const parts = kcm.songName?.split("-");
      setSongName(parts[0]?.trim());
      setSingers(
        parts[1]
          ?.replaceAll("&", ",")
          .replaceAll("/", ",")
          .replaceAll(" ו", ",")
          .replaceAll(" ,", ",")
          .replaceAll(", ", ",")
          .trim()
      );
    }
  }, [kcmList]);
  const updateSongs = async () => {
    const response = await fetch("https://music.318.co.il/api/Songs");
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    let result = await response.json();
    props.updateSongs(result);
  };
  const getAllVolume = async () => {
    const response = await fetch("https://music.318.co.il/api/Songs/volume");
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    let result = await response.json();
    setVolumeList(result);
  };
  const getAllKCM = async (id) => {
    const response = await fetch(
      id
        ? `https://music.318.co.il/api/Songs/kcm/${id}`
        : `https://music.318.co.il/api/Songs/kcm`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    let result = await response.json();
    setKcmList(result);
  };
  useEffect(() => {
    getAllVolume();
    getAllKCM();
  }, []);
  useEffect(() => {
    getAllKCM(volumeSelected);
  }, [volumeSelected]);

  const songExist = async () => {
    const response2 = await fetch(
      `https://music.318.co.il/api/Songs/kcmdone/${kcmList[0].id}`
    );
    if (!response2.ok) {
      alert("Network response was not ok");
    }

    getAllKCM(volumeSelected);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      songName,
      youtubeId,
      singers: !moreSingers ? singers : moreSingers + "," + singers,
    };

    try {
      const response = await fetch("https://music.318.co.il/api/songs", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      //const result = await response.json();
      //props.updateSongs(result);
      if (kcmList[0]?.id) {
        await songExist();
      }

      setYoutubeId("");
      setMoreSingers("");
      setSongName("");
      setYoutubeURL("");
      updateSongs();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function extractYouTubeVideoId(url) {
    // ביטוי רגולרי לחילוץ ID של סרטון YouTube
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;

    // חיפוש התאמה בדפוס
    const match = url.match(regExp);

    // אם נמצאה התאמה, החזר את ה-ID
    setYoutubeId(match && match[2].length === 11 ? match[2] : null);
  }
  const youtubeExist = props.allSongs.find(
    (p) => p.youTubeId.trim() == youtubeId
  );
  const songNameExist = props.allSongs.filter(
    (p) => p.songName.trim() == songName
  );
  const songNameExistMsg =
    songNameExist &&
    songNameExist.map((s) => (
      <li key={s.youTubeId}>
        {s.singers.map((p) => (
          <span
            style={{
              color: singers.split(",").includes(p.name.trim())
                ? "red"
                : "black",
            }}
            key=
            {p.name}
          >
            {p.name}
          </span>
        ))}
      </li>
    ));
  return (
    <div className="">
      {admin !== "0504121074" ? (
        <input
          value={admin}
          onChange={(e) => setAdmin(e.target.value)}
          style={{ fontSize: 12, margin: 0 }}
        ></input>
      ) : (
        <>
          <div style={{ fontSize: 35 }}>
            בחירה מתוך VOLUME -
            <select
              style={{ fontSize: 35 }}
              onChange={(e) => setVolumeSelected(e.target.value)}
            >
              <option value={0}>ללא</option>
              {volumeList.map((vol) => (
                <option key={vol.id} value={vol.id}>
                  {vol.title.trim()}
                </option>
              ))}
            </select>{" "}
            נמצא {kcmList.length} תוצאות
          </div>
          <h1>{props.allSongs.length}</h1>
          {kcmList[0] && (
            <>
              {" "}
              <a
                style={{ fontSize: 35 }}
                href={
                  "https://www.google.com/search?q=" +
                  kcmList[0].songName+" youtube.com"
                }
                // href={
                //   "https://www.youtube.com/results?search_query=" +
                //   kcmList[0].songName
                // }
                target="_blank"
              >
                <div style={{ fontSize: 35 }}> {kcmList[0]?.songName}</div>
              </a>
              <br />
            </>
          )}
          {true && (
            <>
              <button
                style={{
                  width: 200,
                  backgroundColor: "blue",
                  color: "white",
                }}
                onClick={() => songExist()}
              >
                שיר קיים
              </button>
              <br />
            </>
          )}
          <img
            src={`https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`}
            alt="YouTube video thumbnail"
            style={{ heigh: 300, width: 300 }}
            className="image"
          />
          <form onSubmit={handleSubmit}>
            <label style={{ color: youtubeExist ? "red" : "black" }}>
              כתובת היוטיוב:
              <input
                type="text"
                value={youtubeURL}
                onChange={(e) => {
                  setYoutubeURL(e.target.value);
                  extractYouTubeVideoId(e.target.value);
                }}
                style={{ color: youtubeExist ? "red" : "black" }}
              />
            </label>
            <br />
            <label>
              שם השיר:
              <input
                type="text"
                value={songName}
                onChange={(e) => setSongName(e.target.value)}
              />
            </label>
            <br />
            {songNameExist.length > 0 && (
              <div>
                קיים במערכת שירים בשם זה לזמרים:
                <br /> {songNameExistMsg}
              </div>
            )}
            {/* 
        <br /><label>
          מזהה יוטיוב:
          <input
            type="text"
            value={youtubeId}
            onChange={(e) => setYoutubeId(e.target.value)}
          />
        </label> */}{" "}
            הוסף זמר מרשימה:{" "}
            <select
              style={{ fontSize: 25 }}
              onChange={(e) =>
                setSingers(
                  singers
                    ? singers.trim() + "," + e.target.value.trim()
                    : e.target.value.trim()
                )
              }
            >
              <option value={0}>---</option>
              {props.allSingers?.map((s) => (
                <option key={s.id} value={s.name}>
                  {s.name}
                </option>
              ))}
            </select>
            <br />
            <label>
              זמר ראשי:
              <input
                value={singers}
                onChange={(e) => setSingers(e.target.value)}
              />
            </label>
            <br />
            זמרים שיתווספו:
            {singers.split(",")?.map((s) => (
              <span
                key={s}
                style={{
                  color: props.allSingers.find((si) => si.name == s)
                    ? "black"
                    : "red",
                }}
              >
                {s}
                {", "}
              </span>
            ))}
            <br />
            <button type="submit">שלח</button>
            <br />
          </form>
        </>
      )}{" "}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    allSongs: state.allSongs,
    allSingers: state.allSingers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateSongs: (allSongs) => {
      dispatch({
        type: "UPDATE_SONGS",
        payload: allSongs,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSong);
