import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
function AddPlaylistRow(props) {
  const [selectedPlaylist,setSelectedPlaylist] = useState({id:0,playlistName:""})//props.allPlaylists[0])
  
  const changeSelect=(id)=>{
   id=parseInt(id)
    if(id!=0){
      const newSelected ={...props.allPlaylists.find(p=>p.id==id)}
     setSelectedPlaylist(newSelected)
    }else{
setSelectedPlaylist({id:0,playlistName:""})
    }

  }
   useEffect(() => {
    setSelectedPlaylist({id:0,playlistName:""})
    }, [props]);
  const getPlaylists = async () => {
    try {
      const response = await fetch("https://music.318.co.il/api/playlists");

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      let result = await response.json();
       props.updatePlaylists(result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const clickAddPlaylist= async()=>{

const data = {playlistId:selectedPlaylist.id,playlistName:selectedPlaylist.playlistName,songId:props.song.id};
try {
  const response = await fetch("https://music.318.co.il/api/playlists", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const result = await response.json();
  props.updateSongs(result);
  getPlaylists()
 } catch (error) {
  console.error("Error:", error);
}


}
const editNewPlaylist =(name)=>{
  
setSelectedPlaylist({id:0,playlistName:name})
}

  return (
    <div className="">
                  {/* {props.song.singers?.map((ss) => ss.name.trim() + " ")}
                  {" - "}
          {props.song?.songName} */}
              
              <select style={{fontSize:25, height:40}} value={selectedPlaylist.id} onChange={e=>changeSelect(e.target.value)}>
                <option value={0}>חדש</option>
                {props.allPlaylists?.map(p=>
                  <option key={p.id} value={p.id}>{p.playlistName}</option>
                )}
              </select>
              {selectedPlaylist.id==0?<input style={{width:150}} value={selectedPlaylist.playlistName} onChange={e=>editNewPlaylist(e.target.value)}></input>:<></>}
              <button style={{width:170,height:40}} onClick={()=>clickAddPlaylist()}>הוסף  לפלייליסט</button>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    allSongs: state.allSongs,
    allSingers: state.allSingers,
    allPlaylists:state.allPlaylists
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateSongs: (allSongs) => {
      dispatch({
        type: "UPDATE_SONGS",
        payload: allSongs,
      });
    },   updatePlaylists: (playlists) => {
      dispatch({
        type: "UPDATE_ALL_PLAYLISTS",
        payload: playlists,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPlaylistRow);
