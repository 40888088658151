import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import { FaHeart,FaRegHeart,FaHeadphonesAlt  } from "react-icons/fa";

function SingerRow({ item }) {
  const YoutubeThumbnail = ({ videoId }) => {
    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
    return (
      <div className="image-container">
        <img
          src={thumbnailUrl}
          alt="YouTube video thumbnail"
          style={{ width: "100%" }}
          className="image"
        />

        <div className="overlaySinger">
          <div className="singerName">
            <strong>{item.name} 
              {" "}
             <FaHeart /> {" "} {item.likes}
             |{" "} <FaHeadphonesAlt/> {item.played}
            </strong>
          </div>
        </div>
      </div>
    );
  };

  const [isRunning, setIsRunning] = useState(false);
  const [showYoutube, setShowYoutube] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    let intervalId;

    if (isRunning) {
      intervalId = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isRunning]);
  const opts = {
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };
  /* <div className="songName">
        <p>זמן שחלף: {elapsedTime} שניות</p>
      </div> */
  return (
    <div className="singleRow">
      {showYoutube ? (
        <div>
          <div>
            <div className="numberTitle">{item.place}</div>
          </div>
          <div className="songName">
            <strong>{item.singers.map((s) => s.name.trim() + " ")} - </strong>

            {item.songName}
          </div>
          <YouTube
            videoId={item.img.trim()}
            onPlay={() => setIsRunning(true)}
            onPause={() => setIsRunning(false)}
            onEnd={() => setIsRunning(false)}
            className="youtubeInCard"
            opts={opts}
          />
        </div>
      ) : (
        <YoutubeThumbnail videoId={item.img.trim()} />
      )}
    </div>
  );
}

export default SingerRow;
