import React, { useState, useEffect, useRef } from "react";
import SingerRow from "./SingerRow";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Search from "./Search";
function SingersList(props) {
  const [search,setSearch]= useState("")
  const results=search.length>0?props.allSingers.filter(s=>s.name.includes(search)):props.allSingers
  return (
    <>
        <Search searchTxt={search} updateSearchTxt={e=>setSearch(e)}/>
      

 <h3>{results.length}  תוצאות</h3>
    <div className="singers">
      
      {results?.map((singer) => (
        <NavLink key={singer.id} to={`/זמרים/${singer.name}`}>
          <SingerRow item={singer} />
        </NavLink>
      ))}
    </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    allSongs: state.allSongs,
    allSingers: state.allSingers,
  };
};
export default connect(mapStateToProps)(SingersList);
