import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

function AllplaylistScreen(props) {
const clickstartPlaylist=(p)=>{
  props.updateCurrentPlaylist(p)
const songs =props.allSongs.filter(s=>p.songs.includes(s.id))
props.startPlaylist(songs)
props.updateCurrentSongId(songs[0]?.id)
}
  return ( 
    <div >
      <h1>
      פלייליסטים</h1>
      {props?.allPlaylists?.map(p=>
        <div key={p.playlistId} style={{fontSize:30,margin:10}}>{p.playlistName}
        {" "} - {" "}
        {p.songs.length} שירים
        {" "} - {" "}
<button onClick={()=>clickstartPlaylist(p)} style={{width:90}}>התחל</button>
        </div>
      )}
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    allPlaylists:state.allPlaylists,
    user:state.user,
    allSongs:state.allSongs
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentPlaylist: (p) => {
      dispatch({
        type: "UPDATE_CURRENT_PLAYLIST",
        payload: p,
      });
    },  
    updateCurrentSongId: (songId) => {
      dispatch({
        type: "UPDATE_CURRENT_SONGID",
        payload: songId,
      });
    },  
    startPlaylist: (songs) => {
      dispatch({
        type: "START_PLAYLIST",
        payload: songs,
      });
    },  
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(AllplaylistScreen);

