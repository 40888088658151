import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

function PlaylistScreen(props) {
  
    const { name } = useParams();
const clickstartPlaylist=(p)=>{
  props.updateCurrentPlaylist(p)

props.startPlaylist(songs)
props.updateCurrentSongId(songs[0]?.id)
}
const cuurentPlaylist= props?.allPlaylists?.find(p=>p.playlistName.trim()==name)
const songs =props.allSongs.filter(s=>cuurentPlaylist.songs.includes(s.id))
  return ( 
    <div >
      <h1>
  פלייליסט {" "}
  {cuurentPlaylist.playlistName}
  </h1>

  <button onClick={()=>clickstartPlaylist(cuurentPlaylist)} style={{width:90}}>התחל</button>
 {songs.map(s=>
  <div key={s.id}>
    {console.log(s)}
  {  s.singers?.map((s) => s.name + "") + "-" +s.songName}
 </div>
 )}
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    allPlaylists:state.allPlaylists,
    user:state.user,
    allSongs:state.allSongs
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentPlaylist: (p) => {
      dispatch({
        type: "UPDATE_CURRENT_PLAYLIST",
        payload: p,
      });
    },  
    updateCurrentSongId: (songId) => {
      dispatch({
        type: "UPDATE_CURRENT_SONGID",
        payload: songId,
      });
    },  
    startPlaylist: (songs) => {
      dispatch({
        type: "START_PLAYLIST",
        payload: songs,
      });
    },  
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(PlaylistScreen);

