import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import YoutubeCard from "./YoutubeCard";
import AddPlaylist from "./AddPlaylist";
import { GoogleOAuthProvider,GoogleLogin } from '@react-oauth/google';

import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import Playlist from "./Playlist";
import AOS from "aos";
import "aos/dist/aos.css"; // ייבוא סגנונות CSS
import SingRow from "./SingerRow";
import AddSong from "./AddSong";
import SingersList from "./SingersList";
import Login from "./Login";
function MainSite(props) {
  const { page } = useParams();
  useEffect(() => {
    AOS.init();
  }, []);

  const [countdownDate] = useState(new Date("2025-01-03 16:00:00")); // שנה את התאריך בהתאם
  const [isRunning, setIsRunning] = useState(false);
  const [currentSongID, setCurrentSongID] = useState(undefined);
  const [kcmList, setKcmList] = useState([]);
  const [maxResults, setMaxResults] = useState(25);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);
  
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // התאריך עבר, הצג הודעה מתאימה
      return <span>סיימנו!</span>;
    } else {
      return (
        <div>
          <span>{days} ימים </span>
          <span>{hours} שעות </span>
          <span>{minutes} דקות </span>
          <span>{seconds} שניות</span>
        </div>
      );
    }
  };
  useEffect(() => {
    let intervalId;

    if (isRunning) {
      intervalId = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isRunning]);
  const updateList = () => {
    getAllKCM();
    getAllSongs();
  };
  const getAllKCM = async () => {
    const response = await fetch("https://music.318.co.il/api/Songs/kcm");
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    let result = await response.json();
    setKcmList(result);
  };
  useEffect(() => {
    getAllKCM();
  }, []);
  const getAllSongs = async () => {
    try {
      const response = await fetch("https://music.318.co.il/api/songs");

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      let result = await response.json();
      let currentIndex = result.length,
        randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex !== 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [result[currentIndex], result[randomIndex]] = [
          result[randomIndex],
          result[currentIndex],
        ];
      }

      props.updateSongs(result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (!search) {
      setResults(props.allSongs);
    } else {
      const searchLower = search.toLowerCase();

      setResults(
        props.allSongs.filter(
          (song) =>
            song.songName.toLowerCase().includes(searchLower) ||
            song.singers.some((singer) =>
              singer.name.toLowerCase().includes(searchLower)
            )
        )
      );
    }
  }, [search, props.allSongs]);

  return (
    <div className="web">
      {/* <AddPlaylist/> */}
     {props.ifAdmin&&   <AddSong kcm={kcmList[0]} updateList={() => updateList()}></AddSong> } 
      {/* <Countdown date={countdownDate} renderer={renderer} /> */}
     
      {props.allSingers && <SingersList />}
      {/* <div className="web">
        <div className="bigBanner">
          <div className="vs">
            <div>שמוליק סוכות - הימים שעברתי בחושך</div>
            <div className="or">או</div>
            <div>אלייצור - תמיד אוהב אותי</div>
          </div>
          <strong className="atzbaha">להצבעה</strong>
        </div>
        <div className="container">
          <div className="containerFirst">
            <YoutubeCard item={songs[0]} />
          </div>
          <div className="containerSecond">
            <YoutubeCard item={songs[1]} />
          </div>
          <div className="containerSecond">
            <YoutubeCard item={songs[2]} />
          </div>
        </div>
       
      </div> */}
      <br />
      <br />
      {/* <div style={{ fontSize: 30 }}>
        חיפוש שיר:
        <input
          style={{ width: 300 }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div> */}
      <br />
      <br />
      {false && (
        <>
          <div className="Cards">
            {results.slice(0, maxResults).map((s, i) => {
              return (
                <div
                  key={i}
                  data-aos="fade-up"
                  data-aos-offset="100"
                  data-aos-duration={((i % 5) + 2) * 400}
                  style={{
                    border:
                      s.youTubeId.trim() == currentSongID && "3px solid red",
                  }}
                >
                  {}
                  <SingRow
                    item={s}
                    currentSongID={(s) => setCurrentSongID(s)}
                  />
                </div>
              );
            })}
          </div>
          {maxResults < results.length && (
            <div
              className="showMore"
              onMouseOver={() => setMaxResults(maxResults + 25)}
            >
              הצג עוד תוצאות{" "}
            </div>
          )}
        </>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    allSongs: state.allSongs,
    allSingers: state.allSingers,
    ifAdmin: state.ifAdmin,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateSongs: (allSongs) => {
      dispatch({
        type: "UPDATE_SONGS",
        payload: allSongs,
      });
    },    updateIfAdmin: (ifAdmin) => {
      dispatch({
        type: "UPDATE_IFADMIN",
        payload: ifAdmin,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainSite);
