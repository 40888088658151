import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import AddPlaylistRow from "./AddPlaylistRow";
import { connect } from "react-redux";
import { FaHeart,FaRegHeart,FaHeadphonesAlt   } from "react-icons/fa";
import axios from "axios";

function SongRow(props) {
const likeClick=async ()=>{
  if(props.user){

    const data={userToken:props.user.token,
      songId:props.item.id
    }
    try {
      const response = await fetch("https://music.318.co.il/api/Songs/userLike", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      props.updateSongs(result);
      await checkUserToken();
     } catch (error) {
      console.error("Error:", error);
    }
    
  }
}
const addToPlaylist=(song,playNow)=>{
  props.updatePlaylist(song)
  if(playNow)
  props.updateCurrentSongId(song.id)
}

const checkUserToken=async ()=>{
  let userToken = localStorage.getItem('userToken')
  const response = await axios.get(`https://music.318.co.il/api/Users/GetUserByUserToken/${userToken}`);
if(response.data?.token){
props.updateUser(response.data)

}}
  const getPlaylistName=(id)=>{
return props.allPlaylists.find(p=>p.id==id)?.playlistName
  }
  const YoutubeThumbnail = ({ videoId }) => {
    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
    return (
      <>
      <div className="image-container">
        <img
          src={thumbnailUrl}
          alt="YouTube video thumbnail"
          style={{ width: "100%" }}
          className="image"
        />

        <div className="overlay">
          <div className="songName">
            <strong>{props.item.singers.map((s) => s.name.trim() + " ")} - </strong>
            <br />
            {props.item.songName}
            <br/>
            {props.user?.songLikes?.includes(props.item.id)?<FaHeart onClick={()=>likeClick()}/>:<FaRegHeart onClick={()=>likeClick()}/>}
            {props.item.likes.length} אהבו
            <br/>
            <FaHeadphonesAlt/>שמעו {props.item.played} פעמים
              <br/>
          <strong onClick={()=>addToPlaylist(props.item,false)}>הוסף לרשימת השמעה</strong>  
          </div>
          <div className="play-button"  onClick={()=>addToPlaylist(props.item,true)}></div>
        </div>
      </div>
      </>);
  };

  const [isRunning, setIsRunning] = useState(false);
  const [showYoutube, setShowYoutube] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    let intervalId;

    if (isRunning) {
      intervalId = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isRunning]);
  
  useEffect(() => {
    setShowYoutube(false)
  }, [props]);
 
  const opts = {
    width: "100%",
    height: '150',
    playerVars: {
      
      autoplay: 1,
    },
  };
  /* <div className="songName">
        <p>זמן שחלף: {elapsedTime} שניות</p>
      </div> */
  return ( 
    <div className="singleRow">
      {showYoutube ? (
        <div>
          <div className="songName">
            <strong>{props.item.singers.map((s) => s.name.trim() + " ")} - </strong>

            {props.item.songName}
          </div>
          <YouTube
            videoId={props.item.youTubeId.trim()}
            onPlay={() => setIsRunning(true)}
            onPause={() => setIsRunning(false)}
            onEnd={() => setIsRunning(false)}
            className="youtubeInCard"
            opts={opts}

          />
        </div>
      ) : (
        <YoutubeThumbnail videoId={props.item.youTubeId.trim()} />
      )}
      {props.ifAdmin&&<>
      {props.item.playlists.length>0  &&
      <div> 
      {props.item.playlists.map(p=>
        
        <div key={p}
        style={{background:"orange", display:"inline-block",padding:"5px 10px",fontSize:20, borderRadius:10,margin:5}}
        >{getPlaylistName(p)+" "}</div>)}</div>}
      <AddPlaylistRow song={props.item}/></>}
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    allPlaylists:state.allPlaylists,
    ifAdmin:state.ifAdmin,
    user:state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateSongs: (allSongs) => {
      dispatch({
        type: "UPDATE_SONGS",
        payload: allSongs,
      });
    },  
    updateUser: (user) => {
      dispatch({
        type: "UPDATE_USER",
        payload: user,
      });
    },  
    updateCurrentSongId: (songId) => {
      dispatch({
        type: "UPDATE_CURRENT_SONGID",
        payload: songId,
      });
    },  
    updatePlaylist: (song) => {
      dispatch({
        type: "ADD_TO_PLAYLIST",
        payload: song,
      });
    },  
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(SongRow);

