import React, { useState, useEffect, useRef } from "react";
import YouTube, { YouTubePlayer } from "react-youtube";
import { connect } from "react-redux";
import Slider from "@mui/material/Slider";

import ReactPlayer from "react-player";
let videoElement = null;
function Playlist(props) {
  var cElement = null;
  const [current, setCurrent] = useState(0);
  const [isRunning, setIsRunning] = useState(true);
  const [volume, setVolume] = useState(0.5);
  const opts = {
    width: "100%",
    playerVars: {
       autoplay: 1,
    },
  };
    const [elapsedTime, setElapsedTime] = useState(0);
  
  const [currentTime, setCurrentTime] = useState(0); 
  const [currentDuration, setCurrentDuration] = useState(0);
  const playerRef = useRef(null); 

  // useEffect(() => {
  //   if (currentSongID) {
  //     console.log(props.allSongs[0]);
  //     setCurrent(
  //       props.allSongs.findIndex(
  //         (song) => song.youTubeId.trim() === currentSongID.trim()
  //       )
  //     );
  //   }
  // }, [currentSongID]);
 // console.log(props.currentPlaylist)
  useEffect(() => {
  //  console.log(props)
  //alert(props.currentSongId)
  }, [props.currentSongId]);

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
  };

  const _onReady = (event) => {
setElapsedTime(0)
    playerRef.current = event.target;
  };
  const formatTime = (seconds) => {
    if (seconds) {
      const minutes = Math.floor(seconds / 60); // לחשב את הדקות
      const remainingSeconds = Math.floor(seconds % 60); // לחשב את השניות שנותרו
      return `${String(minutes).padStart(2, "0")}:${String(
        remainingSeconds
      ).padStart(2, "0")}`;
    }
  };
  const handleProgress = (progress) => {
    setCurrentTime(progress.playedSeconds);
  };
  const handleLoadedMetadata = (duration) => {
    setCurrentDuration(duration);
  };
  const currentSong=props.playlist?.find(p=>p.id==props.currentSongId) 


  const clickReplaceSong=async (i,ifEnd)=>{
     
     const index=props.playlist.findIndex((song) => song.id === props.currentSongId)
  if(props.playlist.length>index+i &&index+i>=0){
    updateHistory()
    props.updateCurrentSongId(props.playlist[index+i]?.id) 
  } else{
    if(ifEnd){
      updateHistory()
    }
  }

  }
  const updateHistory=async ()=>{
const data={userToken:props.user?props.user.token:undefined,
  playlistId:props.currentPlaylist?.playlistId,
  songId:currentSong.id,
  duration:elapsedTime
}
try {
  const response = await fetch("https://music.318.co.il/api/Songs/history", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  const result = await response.json();
setElapsedTime(0)
setCurrentTime(0)
} catch (error) {
  console.error("Error:", error);
}


  }

    useEffect(() => {
      let intervalId;
  
      if (isRunning && currentTime>1) {
        intervalId = setInterval(() => {
          setElapsedTime((prevTime) => prevTime + 1);
        }, 1000);
      } else {
        clearInterval(intervalId);
      }
  
      return () => clearInterval(intervalId);
    }, [isRunning,currentTime]);
  return (
    <div>
    <div className="playlist">
      {currentSong && (
        <>

        {props.currentPlaylist?.playlistName}
          <div>
            {currentSong?.singers?.map((s) => s.name)} - {currentSong?.songName}
          </div>
          <p style={{ width: 200 }}>
            {formatTime(currentTime)}/{formatTime(currentDuration)}
          </p>
          <div style={{ height: 10, display: "none" }}>
     

            <ReactPlayer
              url={`https://www.youtube.com/watch?v=${currentSong?.youTubeId?.trim()}`}
              playing={isRunning}
              onPlay={() => setIsRunning(true)}
              onPause={() => setIsRunning(false)}
              onEnded={() => clickReplaceSong(1,true)}
              opts={opts}
              className="youtubeInCard"
              onReady={_onReady}
              onProgress={handleProgress}
              onDuration={handleLoadedMetadata}
              volume={volume}
            />
          </div>
          
          |          
          {/* <div className="pointer" onClick={() => setCurrent(current + 1)}> */}
          <div className="pointer" onClick={() => clickReplaceSong(1)}>
            ⏭{" "}
          </div>
          {isRunning ? (
            <div className="pointer" onClick={() => setIsRunning(!isRunning)}>
              ⏸{" "}
            </div>
          ) : (
            <div className="pointer" onClick={() => setIsRunning(!isRunning)}>
              ⏵{" "}
            </div>
          )}
          <div className="pointer" onClick={() => clickReplaceSong( - 1)}>
            ⏮
          </div>
          |
          <div className="pointer" onClick={() => setVolume(volume + 0.1)}>
            +
          </div>
          <Slider
            value={volume}
            onChange={handleVolumeChange}
            aria-labelledby="continuous-slider"
            min={0}
            max={1}
            step={0.01}
            style={{ width: "200px" }}
          />
          <div className="pointer" onClick={() => setVolume(volume - 0.1)}>
            -
          </div>
        </>
      )}
      </div>
      {/* <div>{props.playlist.map(p=>
        <div key={p.id}>{p.songName}</div>
      )}</div> */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    playlist: state.playlist,
    currentSongId: state.currentSongId,
    currentPlaylist:state.currentPlaylist
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentSongId: (songId) => {
      dispatch({
        type: "UPDATE_CURRENT_SONGID",
        payload: songId,
      });
    },  
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(Playlist);
