import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import SongRow from "./SongRow";
import { NavLink } from "react-router-dom";
function SingerPage(props) {
  const { page } = useParams();
  const [songs, setSongs] = useState([]);
  useEffect(() => {
    const songsofSinger = props.allSongs?.filter((song) =>
      song.singers.some((singer) => singer.name.trim() == page.trim())
    );
    setSongs(songsofSinger);
  }, [props.allSongs,page]);
const getNextSinger=()=>{
  return props?.allSingers[props.allSingers?.findIndex(s=>s.name==page)+1]
}


const clickstartPlaylist=()=>{
props.startPlaylist(songs)
props.updateCurrentSongId(songs[0]?.id)
}

  useEffect(() => {
    document.title = ' היכל הנגינה : '+page;
  }, []);

  return (
    <div>
      <div className="Breadcrumb">
        <NavLink to={`זמרים`}> כל הזמרים </NavLink>
        {" >>"}
        {page}
      </div>
      <h1 className="pageTitle">{page}</h1>
      <button style={{color:"white",background:"navy"}} onClick={()=>clickstartPlaylist()}> לשמיעת כל השירים של {page} ברצף</button>
      <br/>
      <NavLink to={`זמרים/${getNextSinger()?.name}`}> 
      <h2 style={{display:"inline-block"}}>למעבר לזמר הבא:     
{getNextSinger()?.name}</h2></NavLink>
      <>
        <div className="Cards">
          {songs?.map((s, i) => {
            return (
              <div
                key={i}
                // data-aos="fade-up"
                // data-aos-offset="100"
                // data-aos-duration={((i % 5) + 2) * 400}
                // style={{
                //   border:
                //     s.youTubeId.trim() == currentSongID && "3px solid red",
                // }}
              >
                {/* <NavLink to={`/שירים/${page}/${s.songName.trim()}`}> */}
                  <SongRow item={s} currentSongID={(s) => console.log(s)} />{" "}
                {/* </NavLink> */}
              </div>
            );
          })}
        </div>
      </>
      <NavLink to={`זמרים/${getNextSinger()?.name}`}> 
      <h2 style={{display:"inline-block"}}>למעבר לזמר הבא:     
      {getNextSinger()?.name}</h2></NavLink>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    allSongs: state.allSongs,
    allSingers:state.allSingers
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentSongId: (songId) => {
      dispatch({
        type: "UPDATE_CURRENT_SONGID",
        payload: songId,
      });
    },  
    startPlaylist: (songs) => {
      dispatch({
        type: "START_PLAYLIST",
        payload: songs,
      });
    },  
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(SingerPage);
