import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { legacy_createStore as createStore } from "redux";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root"));
const initState = {
  allSongs: [],
  allSingers: [],
  allPlaylists: [],
  ifAdmin: false,
  user:undefined,
  playlist:[],
  currentSongId:undefined,
  currentPlaylist:undefined,
};
function getUniqueSingers(allSongs) {
  const uniqueSingers = [];
  if (allSongs.length > 0) {
    allSongs.forEach((song) => {
      song.singers.forEach((singer) => {
        const existingSinger = uniqueSingers.find((s) => s.id === singer.id);
        if (!existingSinger) {
          uniqueSingers.push({
            id: singer.id,
            name: singer.name.trim(),
            img: song.youTubeId.trim(),
            likes:song.likes.length,
            played:song.played
          });
        }else{
          existingSinger.likes+=song.likes.length;
          existingSinger.played+=song.played;
        }
      });
    });
    return uniqueSingers.sort((a, b) => a.name.localeCompare(b.name));
  }
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_SONGS":
      state = {
        ...state,
        allSongs: action.payload,
        allSingers: getUniqueSingers(action.payload),
      };
      break;
      case "UPDATE_ALL_PLAYLISTS":
        state = {
          ...state,
          allPlaylists: action.payload,
        };
        break;
        case "UPDATE_IFADMIN":
          state = {
            ...state,
            ifAdmin: action.payload,
          };
        break;
        case "UPDATE_USER":
          state = {
            ...state,
            user: action.payload,
          };
          break;
          case "ADD_TO_PLAYLIST":
            state = {
              ...state,
              playlist: [...state.playlist,action.payload] ,
            };
            break;
            case "START_PLAYLIST":
              state = {
                ...state,
                playlist: action.payload,
              };
              break;
              case "UPDATE_CURRENT_SONGID":
                state = {
                  ...state,
                  currentSongId: action.payload,
                };
                break;
                case "UPDATE_CURRENT_PLAYLIST":
                  state = {
                    ...state,
                    currentPlaylist: action.payload,
                  };
              break;
  }
  return state;
};

const store = createStore(reducer);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
