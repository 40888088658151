import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import AddPlaylistRow from "./AddPlaylistRow";
function AddPlaylist(props) {
  const [admin, setAdmin] = useState("0504121074");
  const [search, setSearch] = useState("ח");
  const [playlist, setPlaylist] = useState([]);
    const [results, setResults] = useState([]);
   useEffect(() => {
      if (!search) {
        setResults(props.allSongs);
      } else {
        const searchLower = search.toLowerCase();
  
        setResults(
          props.allSongs.filter(
            (song) =>
              song.songName.toLowerCase().includes(searchLower) ||
              song.singers.some((singer) =>
                singer.name.toLowerCase().includes(searchLower)
              )
          )
        );
      }
    }, [search, props.allSongs]);
   
  const getAllPlaylists = async () => {
    const response = await fetch( `https://music.318.co.il/api/playlists`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    let result = await response.json();
    setPlaylist(result);
  };
  useEffect(() => {
    getAllPlaylists();
  }, []);
  return (
    <div className="">
      {admin !== "0504121074" ? (
        <input
          value={admin}
          onChange={(e) => setAdmin(e.target.value)}
          style={{ fontSize: 12, margin: 0 }}
        ></input>
      ) : (
        <>
        <div style={{fontSize:30}}>חיפוש:
          <input value={search} onChange ={e=>setSearch(e.target.value)} />
          <br/>
          {results.slice(0,10).map((s, i) => {
              return (
                <div
                  key={i}
              style={{display:"flex",padding:20, borderBottom:"1px solid grey", justifyContent:"center",alignContent:"center",gap:30, alignItems:"center"}}
                >
<AddPlaylistRow song={s} Playlist={playlist}/>
 </div>
              );
            })}
          
        </div>
        </>
      )}{" "}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    allSongs: state.allSongs,
    allSingers: state.allSingers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateSongs: (allSongs) => {
      dispatch({
        type: "UPDATE_SONGS",
        payload: allSongs,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPlaylist);
